import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ListingPage from '../../components/Shared/ListingPage'
import { ListTable } from '../../components/Shared/ListingTable'
import { StoreApi } from '../../api/storeApi'
import Store from '../../components/Store/store'
import * as notificationActions from "../../actions/notificationAction"
import ListTableActions from '../../components/Shared/ListingTable/listTableActions'
import { bindActionCreators } from 'redux'
import { getSerialNumber } from '../../utils/sharedFunctions'
import { storeSorting } from '../../config/sortingLabels'
import useListingActions from '../../hooks/useListingActions'
import { Tab, Tabs } from '@mui/material'

const StoreContainer = () => {
  const {
    data,
    count,
    page,
    perPage,
    searchTerm,
    sortOptionSelected,
    handlerForSearchingAndSortingChange,
  } = useListingActions('stores', StoreApi.getStores);

  const [tabIndex, setTabIndex] = useState(0)
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    if (tabIndex === 0) {
      setFilteredData(data.filter(store => store.is_live))
    } else {
      setFilteredData(data.filter(store => !store.is_live))
    }
  }, [tabIndex, data])

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex)
  }

  const tableActions = (
    <ListTableActions
      searchText='Search Stores'
      searchTerm={searchTerm}
      sortOptionSelected={sortOptionSelected}
      getData={handlerForSearchingAndSortingChange}
      sortOptions={storeSorting}
    />
  )

  const tableContent = storeTableContent(filteredData, page, perPage)

  return (
    <ListingPage
      title="Store Settings"
      table={
        <>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="store tabs">
            <Tab label="Live" />
            <Tab label="Offline" />
          </Tabs>
          <ListTable
            tableHeadings={tableContent.heading}
            tableContent={tableContent.body}
            tableActions={tableActions}
            count={filteredData.length}
            page_no={page}
            rowsPerPage={perPage}
            onPageChange={(event, pageNo) => handlerForSearchingAndSortingChange('page', pageNo + 1)}
            handleChangeRowsPerPage={(event) => handlerForSearchingAndSortingChange('pageSize', event.target.value)}
            rowsControl={true} />
        </>
      } />
  )
}

const storeTableContent = (stores=[], page_no, per_page) => {
  const body = stores.map((store, index) => (
    <Store key={store.id} {...store} index={getSerialNumber(page_no, per_page, index)} />
  ))
  return { body: body ?? [], heading: ['Id', '', 'Name', 'Company Name', 'Live?'] }
}

const mapDispatchToProps = dispatch => {
  return {
    notifyActions: bindActionCreators(notificationActions, dispatch)
  }
}

export default connect(mapDispatchToProps)(StoreContainer)
