import {HttpClient} from './httpClient' 
import {API_URL} from './RestAPI'
import apiHeaders from './apiHeaders'
import {setUrlForGetRequest} from './sharedFunctions'

//Setting the store API URI
const STORE_API = `${API_URL}/stores`
const CURRENCY_API = `${API_URL}/currencies`

const getCurrencies = () => {
  return HttpClient.get(
    `${CURRENCY_API}/`,
    apiHeaders()
  )
}

// Get
const getStore = (id) => {
  return HttpClient.get(`${STORE_API}/${id}`, apiHeaders())
}

// Read
const getStores = (params) => {
  let url = setUrlForGetRequest(STORE_API, params)
  return HttpClient.get(url, apiHeaders())
}

// Update
const updateStore = (id, store) => {
  return HttpClient.put(
    `${STORE_API}/${id}/update`,
    store,
    apiHeaders()
  )
}

const validateStoreUrl = (payload) => {
  return HttpClient.post(
    `${STORE_API}/store-url`,
    payload,
    apiHeaders()
  )
}

//Encapsulating in a JSON object
const StoreApi = {getStore, getStores, updateStore, validateStoreUrl, getCurrencies}

export {StoreApi}
